import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form } from 'antd'
import * as actions from '../actions'

const CreateCompany = ({ createCompany, callback }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )
  const onFinishHandler = async values => {
    values.crmType = crmType
    await createCompany(values)
    callback()
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <Button type='primary' className='add-new-site_button' onClick={() => setVisible(true)}>
        + Create Company
      </Button>
      <Drawer
        title='Create Company'
        className={'add_product_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please input company name!' }]}>
            <Input />
          </Form.Item>

          {crmType === 'response' ? (
            <Form.Item name='guid' label='Guid' rules={[{ required: true, message: 'Please input company guid!' }]}>
              <Input />
            </Form.Item>
          ) : null}
          {crmType === 'sublytics' || crmType === 'sticky' ? (
            <div>
              <Form.Item
                name='user_id'
                label='CRM User Id'
                rules={[{ required: true, message: 'Please input company user_id!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='user_password'
                label='CRM User Password'
                rules={[{ required: true, message: 'Please input company user_password!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='apiURL'
                label='CRM apiURL'
                rules={[{ required: true, message: 'Please input company apiURL!' }]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}
          {crmType === 'konnektive' || crmType === 'checkoutchamp' || crmType === 'nmi' || crmType === 'rocketgate' ? (
            <div>
              <Form.Item
                name='user_id'
                label='CRM API User'
                rules={[{ required: true, message: 'Please input company user_id!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='user_password'
                label='CRM API User Password'
                rules={[{ required: true, message: 'Please input company user_password!' }]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}
          {crmType === 'pockyt' ? (
            <div>
              <Form.Item
                name='apiURL'
                label='CRM apiURL'
                rules={[{ required: true, message: 'Please input company apiURL!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='descriptor'
                label='Descriptor'
                rules={[{ required: true, message: 'Please input descriptor!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name='guid' label='Token' rules={[{ required: true, message: 'Please input Token!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name='user_id'
                label='Merchant No'
                rules={[{ required: true, message: 'Please input company merchantNo!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='user_password'
                label='Store No'
                rules={[{ required: true, message: 'Please input company storeNo!' }]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}

          <Form.Item name='paayKey' label='Paay Key' rules={[{ max: 200 }]}>
            <Input />
          </Form.Item>
          <Form.Item name='rebatesKey' label='Rebates Key'>
            <Input />
          </Form.Item>
          <Form.Item name='chargeDelay' label='Delay in Hours for Charge' rules={[{ max: 200 }]} initialValue={7}>
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={7}
            name='delayAttempt'
            label='Delay in Hours for Next Attempt'
            rules={[{ max: 200 }]}
          >
            <Input />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapDispatchToProps = {
  createCompany: actions.createCompany,
}

export default connect(null, mapDispatchToProps)(CreateCompany)
